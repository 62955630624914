import React from "react";
import { connect } from "react-redux";
import { SetLogout } from "../../redux/actions";
import { AiOutlinePoweroff } from "react-icons/ai";

const Logout = ({ setShow }) => {
  return (
    <div className="position-absolute p-4" style={{ right: "0", top: "0" }} onClick={() => setShow(true)}>
      <AiOutlinePoweroff color="var(--bs-danger)" />
    </div>
  );
};

const mapDispatsToProps = (dispatch) => ({ setShow: (value) => dispatch(SetLogout(value)) });

export default connect(null, mapDispatsToProps)(Logout);
