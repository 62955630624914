import React from "react";
import Logo from "../../assets/logo.svg";
import { motion } from "framer-motion";
import { connect } from "react-redux";
import { SetAbout } from "../../redux/actions";

const ModalAbout = ({ show, setShow }) => {
  const variants = {
    open: { opacity: 2, pointerEvents: "auto" },
    closed: {
      opacity: 0,
      zIndex: "-1!important",
      pointerEvents: "none",
    },
  };

  return (
    <motion.div
      variants={variants}
      initial="closed"
      exit="closed"
      transition={{ duration: 0.5, ease: "easeInOut" }}
      animate={`${show ? "open" : "closed"}`}
      className="About-modal d-flex align-items-center justify-content-center"
      onClick={() => setShow(false)}
    >
      <div
        className="justify-content-center
        align-items-center bg-dark rounded shadow About-back w-100 mx-2"
        style={{ maxWidth: "600px" }}
      >
        <div className="border border-warning border-1 py-5 rounded">
          <div className="">
            <div className="p-5 text-center text-dark ">
              <img src={Logo} alt="Day-One" className="w-25 logoShadow" />
              <h2 className="mt-5">{process.env.REACT_APP_PRODUCT_NAME}</h2>
              <div>
                <h6 className="mb-4">{process.env.REACT_APP_VERSION}</h6>
                <h6 className="text-nowrap">Μανιατάκος Παναγιώτης</h6>
                <h6 className="text-nowrap mt-4">© Day-One {new Date().getFullYear()}</h6>
                <small>All rights reserved</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const mapStateToProps = (state) => ({ show: state.about });

const mapDispatchToProps = (dispatch) => ({ setShow: (value) => dispatch(SetAbout(value)) });

export default connect(mapStateToProps, mapDispatchToProps)(ModalAbout);
