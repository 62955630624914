/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { ButtonGroup, Modal, Button } from "react-bootstrap";

const ModalSelectAuth = ({ show, setShow, setCurrentVal, selected, data, locate, change }) => {
  const [startHeight, setStartHeight] = useState(0);
  const CarouselRef = useRef();
  const LineRef = useRef();
  const BodyRef = useRef();

  useEffect(() => {
    if (show) {
      setStartHeight(BodyRef.current.offsetHeight);
      [].slice.call(LineRef.current.children).forEach(function (ele) {
        if (ele.attributes.keyvalue.value === selected.code) {
          LineRef.current.scrollTo(0, ele.offsetTop - ele.offsetHeight);
        }
      });
    }
  }, [show]);

  const handleSelect = () => {
    [].slice.call(CarouselRef.current.children).forEach(function (ele) {
      var top = LineRef.current.getBoundingClientRect().top;
      var bottom = LineRef.currentne.getBoundingClientRect().bottom;
      var eleTop = ele.getBoundingClientRect().top;
      var eleBottom = ele.getBoundingClientRect().bottom;
      if (eleBottom < bottom && eleTop > top) {
        setShow(false);
        setCurrentVal({
          name: ele.innerText,
          code: ele.attributes.keyvalue.value,
        });
        change({
          name: ele.innerText,
          code: ele.attributes.keyvalue.value,
        });
      }
    });
  };

  return (
    <Modal
      show={show}
      fullscreen
      className="d-flex align-items-end"
      dialogClassName="login-selects"
      contentClassName="login-selects-content rounded overflow-hidden"
    >
      <Modal.Body ref={BodyRef} id="erp-select" className="p-0">
        <div
          className="position-absolute"
          ref={LineRef}
          style={{
            width: "100%",
            height: "40%",
            top: "30%",
            background: "var(--bs-gray-400)",
          }}
        ></div>
        <div ref={CarouselRef} id="carousel" style={{ padding: `${startHeight / 3}px 0px` }} className="snap vertical">
          {data.map((item, index) => {
            if (locate === "company") {
              return (
                <div className="snap-item" key={index} keyvalue={item.COMPANY}>
                  {item.COMPANYNAME}
                </div>
              );
            } else if (locate === "branch") {
              return (
                <div className="snap-item" key={index} keyvalue={item.BRANCH}>
                  {item.BRANCHNAME}
                </div>
              );
            } else if (locate === "user") {
              return (
                <div className="snap-item" key={index} keyvalue={item.REFID}>
                  {item.REFIDNAME}
                </div>
              );
            }
          })}
        </div>
      </Modal.Body>

      <Modal.Footer className="w-100 bg-dark shadow p-0">
        <ButtonGroup className="w-100 m-0">
          <Button variant="secondary" className="w-50 p-0" onClick={() => setShow(false)}>
            Ακύρωση
          </Button>
          <Button className="w-50" onClick={handleSelect}>
            Εφαρμογή
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalSelectAuth;
