import React, { useState } from "react";
import FirstLogin from "./FirstLogin";
import SecondLogin from "./SecondLogin";

const Login = () => {
  const [allOk, setAllOk] = useState(false);
  const [login, setLogin] = useState({});

  return (
    <div className="Login">
      {!allOk && <FirstLogin setAllOk={setAllOk} setLogin={setLogin} />}
      {allOk && <SecondLogin allOk={allOk} login={login} />}
    </div>
  );
};

export default Login;
