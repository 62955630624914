import Home from "../components/home/Home";
import Logout from "../components/ui/Logout";
import ModalAbout from "../components/ui/ModalAbout";
import ModalLogout from "../components/ui/ModalLogout";
import PoweredBy from "../components/ui/PoweredBy";

function App() {
  return (
    <div className="App vh-100">
      <Home />
      <PoweredBy />
      <Logout />
      <ModalAbout />
      <ModalLogout />
    </div>
  );
}

export default App;
