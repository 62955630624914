import React, { useState } from "react";
import { Button } from "react-bootstrap";
import CameraModal from "./CameraModal";

const Home = () => {
  const [direction, setDirection] = useState("");
  const [show, setShow] = useState(false);
  const [text, setText] = useState("");

  const handleEntrance = () => {
    setText("");
    setDirection("in");
    setShow(true);
  };

  const handleExit = () => {
    setText("");
    setDirection("out");
    setShow(true);
    console.log(direction);
    console.log(text);
  };

  return (
    <div className="Login d-flex flex-column justify-content-center align-items-center h-100 w-100">
      <div className="d-flex justify-content-evenly w-100">
        <Button variant="success" onClick={handleEntrance}>
          Εισοδος
        </Button>
        <Button variant="danger" onClick={handleExit}>
          Έξοδος
        </Button>
      </div>
      <CameraModal show={show} setShow={setShow} setText={setText} />
    </div>
  );
};

export default Home;
