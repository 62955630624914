import React, { useEffect, useRef, useState } from "react";
import { Card, FloatingLabel, Form, InputGroup, Button } from "react-bootstrap";
import { connect } from "react-redux";
import Loader from "../ui/Loader";
import { BsChevronDown } from "react-icons/bs";
import { BiLogIn } from "react-icons/bi";
import { SetSession } from "../../redux/actions";
import authenticate from "../../services/authenticate";
import ModalSelectAuth from "./ModalSelectAuth";

const SecondLogin = ({ handleSetSession, login }) => {
  const [loading, setLoading] = useState(true);
  const submitRef = useRef();
  const [uniqueAuth, setUniqueAuth] = useState(false);

  const [openCompanies, setOpenCompanies] = useState(false);
  const [openBranches, setOpenBranches] = useState(false);
  const [openUsers, setOpenUsers] = useState(false);

  const [company, setCompany] = useState({ code: "", name: "" });
  const [branch, setBranch] = useState({ code: "", name: "" });
  const [user, setUser] = useState({ code: "", name: "" });

  const [companies, setCompanies] = useState([]);
  const [branches, setBranches] = useState([]);
  const [users, setUsers] = useState([]);

  const onCompanyChange = (company) => {
    let branchesArray = [];
    for (let i = 0; i < login.objs.length; i++) {
      if (login.objs[i].COMPANY === company.code) {
        branchesArray.push(login.objs[i]);
      }
    }
    branchesArray = [...new Map(branchesArray.map((item) => [item.BRANCH, item])).values()];
    setBranches(branchesArray);
    setBranch({
      code: branchesArray[0].BRANCH,
      name: branchesArray[0].BRANCHNAME,
    });

    let userArray = [];
    for (let i = 0; i < login.objs.length; i++) {
      if (login.objs[i].COMPANY === company.code && login.objs[i].BRANCH === branch.code) {
        userArray.push(login.objs[i]);
      }
    }
    userArray = [...new Map(userArray.map((item) => [item.REFID, item])).values()];
    setUsers(userArray);
    setUser({
      code: userArray[0].REFID,
      name: userArray[0].REFIDNAME,
    });
  };

  const onBranchChange = (branch) => {
    let userArray = [];
    for (let i = 0; i < login.objs.length; i++) {
      if ((login.objs[i].COMPANY = company.code) && (login.objs[i].BRANCH = branch.code)) {
        userArray.push(login.objs[i]);
      }
    }
    setUsers([...new Map(userArray.map((item) => [item.REFID, item])).values()]);
  };

  const handleAuthenticate = async (e) => {
    e && e.preventDefault();
    setLoading(true);
    const Company = company.code === "" ? login.objs[0].COMPANY : company.code;
    const Branch = branch.code === "" ? login.objs[0].BRANCH : branch.code;
    const RefID = user.code === "" ? login.objs[0].REFID : user.code;
    const Authenticate = await authenticate({
      username: login.username,
      company: Company,
      branch: Branch,
      refID: RefID,
      module: 0,
      sn: login.sn,
      clientID: login.clientID,
    });
    if (Authenticate.success) {
      Authenticate.success = undefined;
      handleSetSession(Authenticate);
    } else {
      console.log(Authenticate);
      setLoading(false);
    }
  };

  useEffect(() => {
    var coomm = login?.objs ? [...new Map(login.objs.map((item) => [item.COMPANY, item])).values()] : [];
    setCompanies(coomm);
    setCompany({
      code: coomm[0].COMPANY,
      name: coomm[0].COMPANYNAME,
    });
    let objs = login.objs;
    let branchesArray = [];
    for (let i = 0; i < objs.length; i++) {
      if (objs[i].COMPANY === coomm[0].COMPANY) {
        branchesArray.push(objs[i]);
      }
    }
    branchesArray = [...new Map(branchesArray.map((item) => [item.BRANCH, item])).values()];
    setBranches(branchesArray);
    setBranch({
      code: branchesArray[0].BRANCH,
      name: branchesArray[0].BRANCHNAME,
    });
    let userArray = [];
    for (let i = 0; i < objs.length; i++) {
      if (objs[i].COMPANY === coomm[0].COMPANY && objs[i].BRANCH === branchesArray[0].BRANCH) {
        userArray.push(objs[i]);
      }
    }
    userArray = [...new Map(userArray.map((item) => [item.REFID, item])).values()];
    setUsers(userArray);
    setUser({
      code: userArray[0].REFID,
      name: userArray[0].REFIDNAME,
    });

    if (coomm.length === 1) {
      setTimeout(() => setUniqueAuth(true), 500);
      setTimeout(handleAuthenticate, 1000); // eslint-disable-line
    }
    setLoading(false);
  }, []); // eslint-disable-line

  const component = [
    {
      controlId: "company",
      label: "Εταιρεία",
      value: company.name,
      keyValue: company.code,
      function: setCompany,
      functionModal: setOpenCompanies,
      functionOnChange: onCompanyChange,
    },
    {
      controlId: "branch",
      label: "Υπ/μα",
      value: branch.name,
      keyValue: branch.code,
      function: setBranch,
      functionModal: setOpenBranches,
      functionOnChange: onBranchChange,
    },
    {
      controlId: "user",
      label: "Χρήστης",
      value: user.name,
      keyValue: user.code,
      function: setUser,
      functionModal: setOpenUsers,
      functionOnChange: () => {},
    },
  ];

  return (
    <div className="w-100 p-2" style={{ maxWidth: "460px" }}>
      {loading && <Loader />}
      <Card className="bg-dark shadow p-2">
        <Form onSubmit={handleAuthenticate} ref={submitRef}>
          {component.map((item, index) => {
            return (
              <InputGroup
                className="crm-floating-with-icon my-1 text-light"
                onClick={() => item.functionModal(true)}
                key={index}
              >
                <FloatingLabel
                  controlId={item.controlId}
                  label={item.label}
                  style={{ whiteSpace: "nowrap" }}
                  className="w-100"
                >
                  <Form.Control
                    className="crm-input ps-1 mt-1 bg-dark text-light"
                    autoCapitalize="off"
                    placeholder={item.label}
                    name={item.controlId}
                    required
                    value={item.value}
                    onChange={(e) => {
                      e.target.blur();
                      item.function(e.target.value);
                    }}
                    onClick={(e) => e.target.blur()}
                  />
                </FloatingLabel>
                <div className="d-flex align-items-center justify-content-center">
                  <BsChevronDown size="1.5rem" color={"var(--bs-primary)"} />
                </div>
              </InputGroup>
            );
          })}
          <Button
            disabled={loading}
            className="w-100 hoverIconAnimation-s mt-1"
            type="submit"
            variant={`${uniqueAuth ? "success" : "warning"}`}
          >
            <b>Σύνδεση</b> <BiLogIn />
          </Button>
        </Form>
      </Card>
      <ModalSelectAuth
        show={openCompanies}
        setShow={setOpenCompanies}
        setCurrentVal={setCompany}
        selected={company}
        data={companies}
        locate="company"
        change={onCompanyChange}
      />
      <ModalSelectAuth
        show={openBranches}
        setShow={setOpenBranches}
        setCurrentVal={setBranch}
        selected={branch}
        data={branches}
        locate="branch"
        change={onBranchChange}
      />
      <ModalSelectAuth
        show={openUsers}
        setShow={setOpenUsers}
        setCurrentVal={setUser}
        selected={user}
        data={users}
        locate="user"
        change={() => {}}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({ handleSetSession: (value) => dispatch(SetSession(value)) });

export default connect(null, mapDispatchToProps)(SecondLogin);
