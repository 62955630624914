import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { connect } from "react-redux";

const PrivateRoute = ({ session }) => {
  return session !== null ? <Outlet /> : <Navigate to="/login" />;
};

const mapStateToProps = (state) => ({ session: state.session });

export default connect(mapStateToProps)(PrivateRoute);
