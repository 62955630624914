import React from "react";

const CameraFrame = () => (
  <div
    style={{
      top: "0px",
      left: "0px",
      zIndex: 1,
      boxSizing: "border-box",
      border: "50px solid rgba(0, 0, 0, 0.3)",
      boxShadow: "rgba(255, 0, 0, 0.5) 0px 0px 0px 5px inset",
      position: "absolute",
      width: "100%",
      height: "100%",
    }}
  />
);

export default CameraFrame;
