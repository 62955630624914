/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { QrReader } from "react-qr-reader";
import CameraFrame from "./CameraFrame";

const CameraModal = ({ show, setShow, setText }) => {
  const bodyRef = useRef();
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (show) {
      setHeight(bodyRef.current.offsetHeight);
    }
  }, [show]);

  const handleScan = (text) => {
    console.log(text);
    setText(text);
    setShow(false);
  };

  return (
    <Modal
      centered
      fullscreen
      show={show}
      onHide={() => setShow(false)}
      dialogClassName="p-4"
      contentClassName="bg-dark text-light"
    >
      <Modal.Header className="d-flex justify-content-center align-items-center text-center">
        <h3>Τοποθετήστε το QRCode σας αναμεσα στο πλαίσιο</h3>
      </Modal.Header>
      <Modal.Body ref={bodyRef}>
        <QrReader
          constraints={{ facingMode: "user" }}
          scanDelay={100}
          ViewFinder={() => <CameraFrame />}
          className="camera-section"
          onResult={(result, error) => {
            if (!!result) {
              handleScan(result?.text);
            }

            // if (!!error) {
            //   console.log(error);
            // }
          }}
          style={{ width: "100%", height: "100%" }}
          videoStyle={{ objectFit: "cover" }}
        />
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center align-items-center text-center">
        <Button onClick={() => setShow(false)}>Κλείσμο</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CameraModal;
