export const SetLogin = (value) => ({
  type: "SET_LOGIN",
  value,
});

export const SetSession = (value) => ({
  type: "SET_SESSION",
  value,
});

export const ClearSession = () => ({
  type: "CLEAR_SESSION",
});

export const SetAbout = (value) => ({
  type: "SET_ABOUT",
  value,
});

export const SetLogout = (value) => ({
  type: "SET_LOGOUT",
  value,
});
