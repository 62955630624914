import axios from "axios";

const authenticate = async ({ sn, clientID, company, branch, module, refID }) =>
  await axios
    .post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
      url: `https://${sn}.oncloud.gr/s1services`,
      sn,
      data: {
        service: "authenticate",
        clientID,
        company,
        branch,
        module,
        refID,
        appID: process.env.REACT_APP_SOFTONE_APPID,
      },
    })
    .then((res) => res.data)
    .catch((er) => ({ success: false, error: er.message }));
export default authenticate;
