import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { connect } from "react-redux";

const PublicRoute = ({ session }) => {
  return session !== null ? <Navigate to="/" /> : <Outlet />;
};

const mapStateToProps = (state) => ({ session: state.session });

export default connect(mapStateToProps)(PublicRoute);
