import React from "react";
import { connect } from "react-redux";
import Logo from "../../assets/logo.svg";
import { SetAbout } from "../../redux/actions";

const PoweredBy = ({ handleSetAbout }) => {
  return (
    <div className="position-absolute" style={{ right: "1rem", bottom: "1rem" }} onClick={() => handleSetAbout(true)}>
      <img src={Logo} alt="Day-One" className="pe-none p-1" />
      <p className="font-weight-light text-center w-100 text-light mb-0" style={{ fontSize: "0.5rem" }}>
        powered by
      </p>
    </div>
  );
};

const mapDispatsToProps = (dispatch) => ({ handleSetAbout: (value) => dispatch(SetAbout(value)) });

export default connect(null, mapDispatsToProps)(PoweredBy);
