import React, { useEffect, useState } from "react";
import { Button, Card, FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { TiDeleteOutline } from "react-icons/ti";
import { connect } from "react-redux";
import { SetLogin } from "../../redux/actions";
import login from "../../services/login";
import Loader from "../ui/Loader";

const FirstLogin = ({ setLogin, handleSetLogin, setAllOk }) => {
  const [connection, setConnection] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [wrongConnection, setWrongConnection] = useState(false);
  const [wrongPassUser, setWrongPassUser] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const previous = JSON.parse(localStorage.getItem("lastlogin"));
    setConnection(previous?.connection ?? "");
    setUsername(previous?.username ?? "");
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setWrongConnection(false);
    setWrongPassUser(false);
    const Login = await login({ url: `https://${connection}.oncloud.gr/s1services`, username, password });
    if (Login.success) {
      handleSetLogin({ username, password, sn: Login.sn, objs: Login.objs, clientID: Login.clientID });
      setLogin({ username, password, sn: Login.sn, objs: Login.objs, clientID: Login.clientID });
      localStorage.setItem("lastlogin", JSON.stringify({ connection, username }));
      setLoading(false);
      setAllOk(true);
    } else {
      console.log(Login);
      if (Login.error === "Request failed with status code 404") {
        setWrongConnection(true);
        setLoading(false);
      } else if (Login.error === "Login fails due to invalid login credentials.") {
        setWrongPassUser(true);
        setLoading(false);
      }
    }
  };

  const component = [
    {
      controlId: "connection",
      label: "Σύνδεση",
      type: "text",
      value: connection,
      condition: wrongConnection,
      function: setConnection,
    },
    {
      controlId: "username",
      label: "Username",
      type: "text",
      value: username,
      condition: wrongPassUser,
      function: setUsername,
    },
    {
      controlId: "password",
      label: "Password",
      type: "password",
      value: password,
      condition: wrongPassUser,
      function: setPassword,
    },
  ];

  return (
    <div className="w-100 p-2" style={{ maxWidth: "460px" }}>
      <Card className="bg-dark shadow p-2">
        {loading && <Loader />}
        <Form onSubmit={handleLogin}>
          {component.map((item, index) => {
            return (
              <InputGroup
                className={`crm-floating-with-icon${item.condition ? " wrong-input" : ""} my-1 text-light`}
                key={index}
              >
                <FloatingLabel
                  controlId={item.controlId}
                  label={item.label}
                  style={{ whiteSpace: "nowrap" }}
                  className="w-100"
                >
                  <Form.Control
                    className="crm-input bg-dark text-light"
                    autoCapitalize="off"
                    placeholder={item.label}
                    type={item.type}
                    name={item.controlId}
                    required
                    value={item.value}
                    onChange={(e) => item.function(e.target.value)}
                  />
                </FloatingLabel>
                {item.value !== "" && (
                  <div className="d-flex align-items-center justify-content-center" onClick={() => item.function("")}>
                    <TiDeleteOutline size="1.75rem" />
                  </div>
                )}
              </InputGroup>
            );
          })}
          <Button disabled={loading} className="w-100 hoverIconAnimation-s mt-1" type="submit" variant="warning">
            Επόμενο
          </Button>
        </Form>
      </Card>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({ handleSetLogin: (value) => dispatch(SetLogin(value)) });

export default connect(null, mapDispatchToProps)(FirstLogin);
