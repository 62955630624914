import { Button, Modal } from "react-bootstrap";
import React from "react";
import { connect } from "react-redux";
import { ClearSession, SetLogout } from "../../redux/actions";

const ModalLogout = ({ show, setShow, logout }) => {
  return (
    <Modal show={show} onHide={() => setShow(false)} centered size="lg">
      <Modal.Header className="border-0 bg-dark p-1" />
      <Modal.Body className=" bg-dark d-flex justify-content-center align-items-center text-center text-light">
        Πρόκειται να αποσυνδεθείτε
      </Modal.Body>
      <Modal.Footer className=" bg-dark d-flex justify-content-evenly align-items-center">
        <Button variant="secondary" onClick={() => setShow(false)}>
          Άκυρο
        </Button>
        <Button
          variant="success"
          onClick={() => {
            setShow(false);
            logout();
          }}
        >
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({ show: state.logout });

const mapDispatchToProps = (dispatch) => ({
  setShow: (value) => dispatch(SetLogout(value)),
  logout: () => dispatch(ClearSession()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalLogout);
