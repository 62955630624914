const session = (state = JSON.parse(localStorage.getItem("session")), action) => {
  switch (action.type) {
    case "SET_SESSION":
      localStorage.setItem("session", JSON.stringify(action.value));
      return action.value;
    case "CLEAR_SESSION":
      localStorage.setItem("session", null);
      return null;
    default:
      return state;
  }
};

const login = (state = JSON.parse(localStorage.getItem("login")), action) => {
  switch (action.type) {
    case "SET_LOGIN":
      localStorage.setItem("login", JSON.stringify(action.value));
      return action.value;
    case "CLEAR_SESSION":
      localStorage.setItem("login", null);
      return null;
    default:
      return state;
  }
};

const about = (state = false, action) => {
  switch (action.type) {
    case "SET_ABOUT":
      return action.value;
    default:
      return state;
  }
};

const logout = (state = false, action) => {
  switch (action.type) {
    case "SET_LOGOUT":
      return action.value;
    default:
      return state;
  }
};

const reducer = (state = {}, action) => ({
  session: session(state.session, action),
  login: login(state.login, action),
  about: about(state.about, action),
  logout: logout(state.logout, action),
});

export default reducer;
